import React from "react";
import Navbar from "../components/Navbar";
import BlockPage from "../components/BlockPage";
import { graphql } from "gatsby";

const BlockPageLayout = ({ data }) => {
  return (
    <div>
      <Navbar />
      <BlockPage data = {data} />
    </div>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        tags
        hero_image {
            childImageSharp {
                gatsbyImageData
            }
        }
        category
      }
      body
    }
  }
`;

export default BlockPageLayout;
export const Head = () => <title>Dyspatch Block Gallery</title>