import React, { useState, useEffect } from "react"
import {
    blockCategory,
    blockDescription,
    tagContainer,
    tagButton,
    codeBlock,
    codeHide,
    viewDml,
} from "./BlockDetails.module.css"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { StaticImage } from "gatsby-plugin-image"

const CopyCode = styled.button`
    cursor: pointer;
    background-color: #9c58bb;
    border-radius: 4px;
    border: none;
    padding: 8px 16px;
    width: 120px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    font-weight: 700;
`

const DMLPlaygroundBtn = styled.button`
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #9c58bb;
    padding: 8px 16px;
    width: 120px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    color: #9c58bb;
    line-height: 20px;
    font-weight: 700;
`

const BlockDetails = (props) => {
    const [view, setView] = useState(false)
    const [code, setCode] = useState(props.code)
    const [isLoaded, setIsLoaded] = useState(false)
    const [playBtnText, setPlayBtnText] = useState("Try it Live")

    useEffect(() => {
        setCode(props.code)
        setIsLoaded(true)
    }, [props.code])

    const getToken = () => {
        setPlayBtnText("Loading...")
        const regex = /(<dys-block>[\s\S]*?<\/dys-block>)/
        const codeText = code
        const match = codeText.match(regex)
        let extractedContent
        if (match && match[1]) {
            extractedContent = match[1].trim()
            extractedContent = extractedContent.replace(/\\n/g, "\n")
        }
        fetch(
            "https://q9ixhcuyuf.execute-api.us-west-2.amazonaws.com/production",
            {
                method: "POST",
                body: JSON.stringify({
                    DMLEditor: extractedContent,
                    sampleData: "{}",
                }),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                window.open(`https://dml-playground.dyspatch.io/${res.token}`)
                setPlayBtnText("Try it Live")
            })
    }

    return (
        <Container>
            <Row>
                <Col>
                    <p
                        style={{
                            marginTop: "114px",
                            fontFamily: "Inter, sans-serif",
                            fontSize: "13.18px",
                        }}
                    >
                        tags
                    </p>
                    <div className={tagContainer}>
                        <p className={blockCategory}>{props.category}</p>
                        {props.tags.split(",").map((tag) => (
                            <p className={tagButton}>{tag}</p>
                        ))}
                    </div>
                    <p className={blockDescription}>{props.description}</p>
                    <div style={{ display: "flex", gap: "8px" }}>
                        <CopyCode onClick={props.copy}>
                            {props.copyText}
                        </CopyCode>
                        <DMLPlaygroundBtn onClick={getToken}>
                            {playBtnText}
                        </DMLPlaygroundBtn>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className={viewDml} onClick={() => setView(!view)}>
                        {view ? (
                            <StaticImage
                                style={{
                                    width: "9px",
                                    height: "6px",
                                    verticalAlign: "middle",
                                    marginRight: "13px",
                                }}
                                src="../images/codeArrowClose.png"
                                alt="Close Arrow"
                            />
                        ) : (
                            <StaticImage
                                style={{
                                    width: "5.5px",
                                    height: "9px",
                                    verticalAlign: "middle",
                                    marginRight: "13px",
                                }}
                                src="../images/codeArrowOpen.png"
                                alt="Open Arrow"
                            />
                        )}
                        <strong> View DML</strong>
                    </p>
                </Col>
            </Row>
            <Row>
                {view && isLoaded && (
                    <Col className={codeBlock}>
                        <MDXRenderer>{code}</MDXRenderer>
                    </Col>
                )}
                {(!view || !isLoaded) && (
                    <Col className={codeHide}>
                        <MDXRenderer>{code}</MDXRenderer>
                    </Col>
                )}
            </Row>
        </Container>
    )
}

export default BlockDetails
