// extracted by mini-css-extract-plugin
export var ampCallout = "BlockDetails-module--amp-callout--56d90";
export var ampCalloutText = "BlockDetails-module--amp-callout-text--62bb1";
export var ampCalloutTitle = "BlockDetails-module--amp-callout-title--616d7";
export var blockCategory = "BlockDetails-module--block-category--8e143";
export var blockDescription = "BlockDetails-module--block-description--1bf84";
export var blockTitle = "BlockDetails-module--block-title--e7898";
export var codeBlock = "BlockDetails-module--code-block--f2eb3";
export var codeHide = "BlockDetails-module--code-hide--77d4a";
export var tagButton = "BlockDetails-module--tag-button--5c951";
export var tagContainer = "BlockDetails-module--tag-container--487d9";
export var viewDml = "BlockDetails-module--view-dml--6ad4c";