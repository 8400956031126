import React, { useState } from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { blockTitle, blockPageContainer } from "./BlockPage.module.css"
import BlockDetails from "./BlockDetails"
import BlockVisual from "./BlockVisual"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Footer from "./Footer"
import Row from "react-bootstrap/Row"

const BlockPage = ({ data }) => {
    const [copyText, setCopyText] = useState("Copy Code")
    const image = getImage(data.mdx.frontmatter.hero_image)

    const copyToClipBoard = () => {
        const codeText = document.getElementsByTagName("pre")[0]
        setCopyText("Copied!")
        navigator.clipboard.writeText(codeText.innerText)

        setTimeout(() => {
            setCopyText("Copy Code")
        }, 2000)
    }

    return (
        <Container fluid>
            <Row className={blockPageContainer}>
                <Col lg={6}>
                    <p>
                        <Link
                            to="/"
                            style={{
                                color: "#9C58BB",
                                fontFamily: "Inter",
                                fontWeight: "700",
                                fontSize: "13px",
                            }}
                        >
                            Back to block gallery
                        </Link>
                    </p>
                    <h1 className={blockTitle}>{data.mdx.frontmatter.title}</h1>
                    <BlockDetails
                        code={data.mdx.body}
                        copyText={copyText}
                        copy={() => copyToClipBoard()}
                        title={data.mdx.frontmatter.title}
                        category={data.mdx.frontmatter.categry}
                        tags={data.mdx.frontmatter.tags}
                        description={data.mdx.frontmatter.description}
                    />
                </Col>
                <Col lg={6}>
                    <BlockVisual
                        copyText={copyText}
                        copy={() => copyToClipBoard()}
                        image={image}
                        code={data.mdx.body}
                    />
                </Col>
            </Row>
            <Footer />
        </Container>
    )
}

export default BlockPage
