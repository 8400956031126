import React, { useEffect, useState } from "react";
import {
  blockVisualContainer,
  blockVisualImage,
  viewIcons,
  overlay,
  overlayButton,
  contentContainer
} from "./BlockVisual.module.css";
import ReactHTMLParser from "html-react-parser";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DesktopIcon from "./customIcons/DesktopIcon";
import MobileIcon from "./customIcons/MobileIcon";

const BlockVisual = (props) => {
  const [dml, setDML] = useState("");
  const [renderedDML, setRenderedDML] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [device, setDevice] = useState("desktop");

  useEffect(() => {
    let mod, inst;
    const Go = global.Go;
    const go = new Go();
    const headers = new Headers();
    headers.append("pragma", "no-cache");
    headers.append("cache-control", "no-cache");

    let wasm = WebAssembly.instantiateStreaming(
      fetch("../dml.wasm", {
        method: "GET",
        headers: headers,
      }),
      go.importObject
    );
    wasm
      .then(async (result) => {
        mod = result.module;
        inst = result.instance;
        await go.run(inst);
        inst = await WebAssembly.instantiate(mod, go.importObject); // reset instance
      })
      .catch((err) => {
        console.error(err);
      });
    wasm
      .then(async (result) => {
        // console.log("Render 1");
        // renderDML();
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    let el = document.getElementsByTagName("pre")[0].innerText;
    setDML(el);
    if (dml) {
      setTimeout(() => {
        setLoaded(true);
        renderDML();
      }, 1000);
    }
  }, [dml]);

  useEffect(() => {
    renderDML();
  }, [device, dml, loaded]);

  const changeDevice = (d) => {
    setDevice(d);
  };

  const renderDML = () => {
    if (global.dml === undefined) {
      return;
    }
    let html = global.dml.render(dml, "", "", "");
    if (html.html !== null) {
      setRenderedDML(html.html);
      let desktop = document.querySelector("#desktop");
      if (desktop !== null) {
        desktop.innerHTML = html.html;
      }
      let mobile = document.querySelector("#mobile");
      if (mobile !== null) {
        mobile.setAttribute("srcdoc", html.html);
      }
    }
  };

  return (
    <Container style={{ width: "650px" }}>
      <Row className={blockVisualContainer}>
        <Col className={blockVisualImage}>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            {device === "desktop" && (
              <Col sm={2}>
                <button
                  style={{ backgroundColor: "rgb(118,118,118)" }}
                  className={viewIcons}
                  onClick={() => changeDevice("desktop")}
                >
                  {" "}
                  <DesktopIcon color="#FFFFFF" />
                </button>
                <button
                  style={{ backgroundColor: "#FFFFFF" }}
                  className={viewIcons}
                  onClick={() => changeDevice("mobile")}
                >
                  <MobileIcon color="rgb(118 118 118)" />
                </button>
              </Col>
            )}
            {device === "mobile" && (
              <Col sm={2}>
                <button
                  style={{ backgroundColor: "#FFFFFF" }}
                  className={viewIcons}
                  onClick={() => changeDevice("desktop")}
                >
                  {" "}
                  <DesktopIcon />
                </button>
                <button
                  style={{ backgroundColor: "rgb(118,118,118)" }}
                  className={viewIcons}
                  onClick={() => changeDevice("mobile")}
                >
                  <MobileIcon color="#FFFFFF" />
                </button>
              </Col>
            )}
          </Row>

          {!loaded && (
            <>
              <p style={{ fontFamily: "Lato, sans-serif" }}>
                Rolling for initiative
              </p>
              <CircularProgress />
            </>
          )}
          {loaded && device === "desktop" && (
            <>
              {ReactHTMLParser(
                renderedDML.replace("p{display: block; margin: 13px 0;}", " ")
              )}
            </>
          )}
          {loaded && device === "mobile" && (
            <iframe height="600" width="400" id="mobile"></iframe>
          )}
          
            {/* <div className={overlay}>
              <button onClick={props.copy} className={overlayButton}>
                {props.copyText}
              </button>
            </div> */}
          
        </Col>
      </Row>
    </Container>
  );
};

export default BlockVisual;
