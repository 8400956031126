import * as React from "react";
const MobileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={16}
    viewBox="0 0 10 16"
    aria-hidden="true"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M7.965 12.357V2.18H1.43v10.178h6.536zm-3.26 2.894c.303 0 .559-.105.768-.314.209-.21.313-.465.313-.767s-.104-.56-.313-.776a1.029 1.029 0 0 0-.767-.322c-.302 0-.56.104-.776.314a1.05 1.05 0 0 0-.322.784c0 .314.104.572.314.776.209.203.47.305.784.305zM7.635 0c.314 0 .61.084.889.253.279.168.5.392.662.67.163.28.244.582.244.907v12.34c0 .325-.081.627-.244.906a1.88 1.88 0 0 1-.662.671 1.691 1.691 0 0 1-.889.253H1.813c-.326 0-.628-.084-.907-.253a1.88 1.88 0 0 1-.662-.67A1.768 1.768 0 0 1 0 14.17V1.83c0-.325.081-.627.244-.906A1.88 1.88 0 0 1 .906.253C1.186.084 1.487 0 1.813 0h5.821z"
    />
  </svg>
);
export default MobileIcon;
