import * as React from "react";
const DesktopIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    viewBox="0 0 16 15"
    aria-hidden="true"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M14.535 8.714V1.429H1.464v7.285h13.07zm0-8.714c.395 0 .738.14 1.028.418.29.28.436.616.436 1.011v8.714c0 .395-.145.738-.436 1.028-.29.29-.633.436-1.028.436H9.463l1.43 2.178v.715H5.105v-.715l1.43-2.178H1.463c-.395 0-.738-.145-1.028-.436A1.406 1.406 0 0 1 0 10.143V1.429C0 1.034.145.697.436.42.726.138 1.069 0 1.464 0h13.07z"
    />
  </svg>
);
export default DesktopIcon;